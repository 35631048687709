<template>
  <div style="width: 80%; margin: 0 auto">
    <h1 style="text-align: center; margin: 3% 0; font-family: PingFang SC">
      安装说明：四步轻松安装谷歌插件
    </h1>
    <span
      style="
        text-align: center;
        display: block;
        margin: 3% 0;
        font-family: PingFang SC;
      "
      >宇起智能密码器设备专用浏览器插件，下载安装宇起谷歌浏览器插件后，添加网站账号可实现指纹快速登录功能</span
    >
    <div>
      <ul>
        <li>
          <h3>01:点击下方“下载”按钮， 下载插件</h3>
          <el-button
            type="primary"
            round
            style="margin: 10.5rem auto; display: block"
            ><a
              style="display: block; padding: 0 1.5625rem" :href="link"
              >下载</a
            ></el-button
          >
          <!-- <a
            class="down"
            href="https://pic.xiaoyu.top/hy-zng/yuqiextension101/odobmlbgkooiliffigpakceichecmhem.crx"
            style="margin: 10rem auto; display: block"
            >下载</a
          ><br /> -->
        </li>
        <li>
          <h3>02:复制下方网址链接，在新的标签页打开，进入扩展程序</h3>
          <el-image :src="url2" :preview-src-list="srcList2"> </el-image><br />
          <!-- <img style="width: 100%" :src="url2" alt="" /> -->
          <span style="font-size: 0.75rem; color: #a8a8a8">点击查看示例图</span>
          <p>
            <span style="display: block; margin: 0.625rem 0">
              chrome://extensions</span
            ><br />
            <!-- <a
              @click="copy('chrome://extensions')"
              class="down"
              style="display: inline-block"
              >复制链接</a
            > -->
            <el-button
              type="primary"
              round
              @click="open2('chrome://extensions')"
              >复制链接</el-button
            >
          </p>
        </li>
        <li>
          <h3>03:在扩展程序右上角，打开开发者模式</h3>
          <el-image
            style="
              width: 75%;
              height: 50%;
              box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            "
            :src="url3"
            :preview-src-list="srcList3"
          >
          </el-image
          ><br />
          <span style="font-size: 0.75rem; color: #a8a8a8">点击查看示例图</span>
        </li>
        <li>
          <h3>04:拖入下载的插件安装包，点击“添加扩展程序”即可</h3>
          <el-image
            style="
              width: 75%;
              height: 50%;  
              box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            "
            :src="url4"
            :preview-src-list="srcList4"
          >
          </el-image
          ><br />
          <span style="font-size: 0.75rem; color: #a8a8a8">点击查看示例图</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getDownload } from "../../../api/home/index";
export default {
  data() {
    return {
      link:'',
      url2: require("../../../assets/2.png"),
      url3: require("../../../assets/3.gif"),
      url4: require("../../../assets/4.gif"),
      srcList2: [require("../../../assets/2.png")],
      srcList3: [require("../../../assets/3.gif")],
      srcList4: [require("../../../assets/4.gif")],
    };
  },
  created(){
    this.getDown()
  },
  methods: {
    open2() {
      this.copy("chrome://extensions");
    },
    copy(val) {
      let copyInput = document.createElement("input"); // 创建标签
      copyInput.value = val; // 标签赋值
      document.body.appendChild(copyInput); // 添加标签
      copyInput.select(); // 选取文本框内容
      document.execCommand("copy"); // 调用浏览器复制
      document.body.removeChild(copyInput); // 复制成功后再将构造的标签 移除
      this.$message({
        message: "复制成功",
        type: "success",
      });
    },
    getDown() {
      getDownload("101").then((res) => {
        this.link = res.data.data.link;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  display: flex; 
  align-content: center;
  justify-content: space-between; 
  font-family: PingFang SC;
  li {
    width: 25%;
    min-width: 12.5rem;
    height: 19.5rem;
    // border: 1px solid #666;
    background: #f8f8f8;
    padding: 1.25rem;
    margin: 0 10px;
    margin-bottom: 3.125rem;
    box-sizing: border-box;
    overflow: hidden;
    text-align: center;
    h3 {
      margin-bottom: 3.25rem;
      height: 1.875rem;
      font-size: 1rem;
      font-family: PingFang SC;
    }
  }
  .down {
    width: 6.25rem;
    padding: 0.5625rem;
    text-align: center;
    background-color: #3bb49d;
    color: #fff;
    border-radius: 0.5625rem;
  }
}
</style>
